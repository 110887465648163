import React, { Fragment } from "react";
import Bayout from "../../components/blogLayout";
import BlogListItem from "../../components/blogListItem";
import { graphql } from "gatsby";

import authorImg from "../../images/blog/author/scott-mcdonald.jpeg";

export default ({ data, pageContext: { numPages, current } }) => {
  const authorBasePath = "/blog/author/scott-mcdonald/";
//Livvi - we all got laid poff!! please keep my name for all the old blog posts if you can!  I appreciate it.
// going forward update the code to pull the actual author name for NEW posts
//but keep me as the author of old posts please ! :) For as long as possible :)
  return (
    <Bayout
      hasPage={true}
      numPages={numPages}
      currentPage={current}
      pageBasePath={authorBasePath}
      showFirstPagePath={true}
    >
      <div className="blog-author d-flex flex-column flex-md-row align-items-start align-items-md-center mb-5">
        <div className="blog-author-img-container mr-0 mr-md-5 mb-4 mb-md-0">
          <img className="blog-author-img" src={authorImg} />
        </div>
        <div className="blog-author-content">
          <div className="blog-author-name text-inter mb-1">
            Scott McDonald
          </div>
          <div className="blog-author-title text-ptmono text-muted text-uppercase mb-3">
            Director of Web Development
          </div>
          <div className="blog-author-description text-inter">
            {"Scott is an expert in Web Development, Search Engine Optimization, and Team Leadership."}
          </div>
        </div>
      </div>

      <hr/>

      <div className="blog-list d-flex flex-column mt-5">
        <div className="blog-list-author text-inter mb-3">
          By Scott McDonald
        </div>
        {data.allWordpressPost.edges.map(({ node }, i) =>
          <Fragment key={node.id}>
            <BlogListItem node={node} />
            {i < data.allWordpressPost.edges.length - 1 && <hr />}
          </Fragment>
        )}
      </div>
    </Bayout>
  )
};

export const query = graphql`
  query($limit:Int!, $skip:Int!) {
    allWordpressPost(sort: {fields: [date], order: [DESC]}, limit: $limit, skip: $skip, filter: {categories: {elemMatch: {name: {ne: "BTT Weekly Update"}}}}){
      edges {
        node {
          title
          excerpt
          path
          id
          date(formatString: "YYYY/MM/DD")
          categories {
            name
            path
          }
          featured_media {
          	source_url
         }
        }
      }
    }
  }
`;
